import { columnGroupTypeData } from '@/utils/constant/productConst'

export const amaOption = {
  topPage: false,
  editBtn: false,
  delBtn: false,
  height: '100%',
  border: true,
  column: [
    {
      label: '字段名称',
      prop: 'excelFieldName',
      search: true,
      hide: true
    },
    {
      label: '字段英文名称',
      prop: 'excelLocalLabelName'
    },
    {
      label: '字段英文名称2',
      prop: 'excelFieldName'
    },
    {
      label: '中文名称',
      prop: 'labelName'
    },
    {
      label: '字段类型',
      prop: 'columnGroupType',
      dicData: columnGroupTypeData
    },
    {
      label: '规则',
      prop: 'rule'
    },
    {
      label: '优先级',
      prop: 'sortNumber'
    }
  ]
}

export const option = {
  topPage: false,
  editBtn: false,
  delBtn: false,
  height: '100%',
  border: true,
  column: [
    {
      label: '字段名称',
      prop: 'fieldName',
      search: true,
      hide: true
    },
    {
      label: '字段英文名称',
      prop: 'labelNameEn'
    },
    {
      label: '字段英文名称2',
      prop: 'labelNameEnTwo'
    },
    {
      label: '中文名称',
      prop: 'labelName'
    },
    {
      label: '字段类型',
      prop: 'columnGroupType',
      dicData: columnGroupTypeData
    },
    {
      label: '规则',
      prop: 'rule'
    },
    {
      label: '优先级',
      prop: 'sortNumber'
    }
  ]
}